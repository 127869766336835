import classname from 'classnames';
import React, { useEffect, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { readMessageAction as readGeneralChatMessageAction } from '@actions/general-chat';
import { readMessageAction as readPrivateChatMessageAction } from '@actions/private-chat';
import { readMessageAction as readPrivateOrderChatMessageAction } from '@actions/private-order-chat';
import DocIcon from '@images/menu-icon-doc.svg';

import TimeMessage from '../time-message';

import './chat-message-item.scss';

const ChatMessageItem = ({ id, text, time, isRead, isReadByAnotherUser, isMine, isGeneralChat, documents, hideReadMark, isOrder }) => {
    const itemRef = useRef();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!isMine && !isRead) {
            var observer = new IntersectionObserver(
                function (entries) {
                    if (entries[0].isIntersecting) {
                        setTimeout(() => {
                            if (isGeneralChat) {
                                dispatch(readGeneralChatMessageAction(id));
                            } else if (isOrder) {
                                dispatch(readPrivateOrderChatMessageAction(id));
                            } else {
                                dispatch(readPrivateChatMessageAction(id));
                            }
                        }, 300);
                    }
                },
                { threshold: [0] },
            );

            const node = itemRef.current;

            observer.observe(node);

            return () => {
                observer.disconnect(node);
            };
        }
    }, [dispatch, id, isGeneralChat, isMine, isOrder, isRead]);

    const styleField = classname('chat-message-field', { right: isMine });

    const messageTime = useMemo(
        () =>
            new Date(Date.parse(time)).toLocaleTimeString('en-Gb', {
                hour: '2-digit',
                minute: '2-digit',
            }),
        [time],
    );

    console.log(documents);

    const documentsComponent = useMemo(() => {
        if (!documents?.length) {
            return [];
        }

        return documents?.map((item, index) => {
            if (!item) {
                return;
            }

            if (item?.isDeleted) {
                return (
                    <div key={index} className='chat-message-field__document'>
                        {item?.name}
                    </div>
                );
            }

            if (item?.name.match(/\.(jpeg|jpg|gif|png)$/) != null) {
                return (
                    <div key={index} className='chat-message-field__document chat-message-field__document-image'>
                        <a href={item?.file_path} target='_blank' rel='noreferrer'>
                            {item?.name}
                        </a>
                        <img src={item?.file_path} />
                    </div>
                );
            }

            return (
                <div key={index} className='chat-message-field__document'>
                    <DocIcon />
                    <a href={item?.file_path} target='_blank' rel='noreferrer'>
                        {item?.name}
                    </a>
                </div>
            );
        });
    }, [documents]);

    return (
        <div className={`chat-message-field__container ${isMine ? 'right' : ''}`}>
            <div className={styleField} ref={itemRef}>
                <div className='chat-message-field__text'>
                    {text}
                    {documentsComponent}
                </div>
            </div>
            <TimeMessage className='chat-message-field__time' time={messageTime} isRead={isReadByAnotherUser} isMine={isMine} hideReadMark={hideReadMark} />
        </div>
    );
};

export default ChatMessageItem;
